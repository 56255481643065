import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { isFullscreenActive } from 'services/user-layout/selectors';
import { updateWantsSidebar } from 'services/user-layout/actions';
import { isMobileLayout as isMobileLayoutSelector, isLandscape as isLandscapeSelector } from 'services/device/selectors';

import { isProfilePanelOpen as isProfilePanelOpenSelector } from './selectors';
import {
  closePanel,
  openPanel,
  requestClosePanel,
  OPEN_PANEL,
  OPEN_PROFILE_PANEL,
  REQUEST_CLOSE_PANEL,
  TOGGLE_PROFILE_PANEL,
} from './actions';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics/actions';
import { isUserAdmin } from 'services/auth/selectors';

export const handleSidebarSaga = function* () {
  yield put(updateWantsSidebar(true));
};

export const handleClosePanelSaga = function* () {
  const isFullscreen: boolean = yield select(isFullscreenActive);
  if (isFullscreen) {
    yield put(updateWantsSidebar(false));
  }
  yield put(closePanel());
};

export const handleOpenProfilePanelSaga = function* () {
  const isMobileLayout: boolean = yield select(isMobileLayoutSelector);
  const isLandscape: boolean = yield select(isLandscapeSelector);
  const isAdmin: boolean = yield select(isUserAdmin);

  // we don't yet open panels including auth in mobile landscape
  if (!(isMobileLayout && isLandscape)) {
    yield put(openPanel({ doc: { data: { kind: 'profile' } }, overCard: true }));
  }

  if (!isAdmin) {
    return;
  }

  const menu = 'main';
  const element = 'profile';
  yield put(trackEvent({ event: AdminActionEvents.NAVBAR, properties: { menu, element } }));
};

export const handleToggleProfilePanelSaga = function* () {
  const isProfilePanelOpen: boolean = yield select(isProfilePanelOpenSelector);
  yield isProfilePanelOpen ? put(requestClosePanel()) : call(handleOpenProfilePanelSaga);
};

const customPanelsSaga = function* () {
  yield takeLatest(OPEN_PANEL, handleSidebarSaga);
  yield takeEvery(REQUEST_CLOSE_PANEL, handleClosePanelSaga);
  yield takeEvery(OPEN_PROFILE_PANEL, handleOpenProfilePanelSaga);
  yield takeEvery(TOGGLE_PROFILE_PANEL, handleToggleProfilePanelSaga);
};

export default customPanelsSaga;
