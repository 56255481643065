import sample from 'lodash/sample';
import { createSelector } from 'reselect';
import hash from 'json-stable-stringify';
import IState from 'services/state';

import {
  BASE_PATH,
  FORCE_ENABLE_MAESTRO_LOGIN,
  LEGACY_DOMAIN,
  SITE_SLUG,
  EMBED,
  COMPOSITE_OVERLAYS,
} from 'config';

import { deduped } from 'array-utils';
import {
  isSidebarVisible,
  isEmbed,
  isVideoEmbed,
  isTheaterEmbed,
  getSidebarWidth,
  isVideoOnlyMode,
  getActivePanelsV2,
} from 'services/user-layout/selectors';
import { isUserAdmin } from 'services/auth/selectors';
import { NO_IMAGE } from 'services/admin/constants';
import { isMobileLayout, isLandscape, getWidth, getDeviceHeight } from 'services/device/selectors';
import {
  isEditMode,
  isBroadcastMode,
  getManagingPageId,
  getMockArtworkRenderer,
  getManagingPageType,
  getAdminBarWidth,
  getDraftHomeId,
  getPendingPageDocs,
  getPendingRegionsData,
  getPendingOfflineContent,
  getPreviewActiveTheme,
  getMockThemeRenderer,
  isEditing,
  getPendingThemesDocs,
} from 'services/admin/selectors';
import { getEditingPageDoc } from 'services/admin/selectors';
import {
  HEADER_HEIGHT,
  SPACING_SMALL,
  SPACING_MEDIUM,
  CHANNEL_SELECT_TOOLTIP_HEIGHT_PX,
  CHANNEL_SELECT_HEIGHT_PX,
  SPACING_LARGE,
} from 'style/constants';
import { getRenderer } from 'services/renderer/selectors';
import { ID_SIDEBAR } from 'services/renderer/ids';
import { persistenceService } from 'services/persistence';
import { getCurrentGateData, isGateActive } from 'services/gate/selectors';
import { defaultProfileImages, defaultCustomTextModal, pageTypes } from './constants';
import { Social, Sponsor, EMPTY } from 'models/IFooter';
import { IObject, ITheme } from 'models';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import { isActivePanel } from 'components/objects/PanelV2/utils';
import { PlayerSpot } from 'services/video';
import { DefaultTheme } from 'styled-components';
import { IClassicThemeOptions, THEME_TYPES } from 'models/ITheme';
import { lazyFn } from 'utils/lazy';
import { PLAYLIST, VIDEO_SPOTLIGHT } from 'components/admin-bridge/AdminGridController/constants';
import { playlistHasYoutubeOrTwitch, videoHasYoutubeOrTwitch } from 'services/video/utils';

const EMPTY_ARRAY: any[] = [];
const EMPTY_OBJECT = {};
const EMPTY_OBJECT_WITH_EMPTY_NAME = { name: '' };

// Created Selectors for combinedSelectors Epic-SRE-216

// End of Created selectors
export const DEFAULT_INSIGHTS_ID = [
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2I',
  'jowLCJkIjp7InVpZCI6InNpdGVfdG9rZW4iLCJfaWQ',
  'iOm51bGwsInNsdWciOm51bGwsImNyZWF0ZWQiOjE1MD',
  'M0MzU5MDMuMDUyfSwiaWF0IjoxNTAzNDM1OTAzfQ.nOr',
  'C_aFDksylltOZg6GnaoWu6kmxmsNIia-4L4rzkaU',
].join('');

// all sites cloned from this slug
export const MAESTRO_BASE_SITE_SLUGS = ['maestro-empty', 'selfsignup-empty'];

// TODO: Selector with side effects, very evil, etc.
// TODO: Should use arrayId but that doesn't fucking work with references for some stupid reason
// TODO: Should be memoized
export const getHomeId = (state: IState) => {
  const settings = state.app.site?.settings || {};
  const abSettings = settings.homeChannelAbTesting || {};
  const { channels: rawChannels = [], enable = false } = abSettings;
  if (enable) {
    const storageKey = 'homeChannelAbVariant';
    const ids = rawChannels.map((item?: { _id: string }) => item?._id).filter((id: string) => id);
    if (ids.length) {
      const persistence = persistenceService();
      const storage = persistence.readStatic<{ id: string }>(storageKey);
      if (storage) {
        const storageId = storage.id;
        if (ids.includes(storageId)) {
          return storageId;
        }
      }
      const id = sample(ids);
      persistence.write(storageKey, { id });
      return id;
    }
  }
  return settings.homeId || null;
};

/**
 * Warning: This selector (getObject) is the source of truth for current page (app.object)
 * We use this property to store changes from firebase
 * PLEASE DON'T MODIFY IT
 */
export const getObject = createSelector(
  (state: IState) => state.app.object,
  (obj) => obj,
);

export const isUnpublished = createSelector(
  (state: IState) => state.app.object?.created,
  (created) => !created,
);
export const getObjectLoaded = (state: IState) => state.app.objectLoaded;
export const isGoogleAnalyticsDisabled = (state: IState) =>
  state.app.site?.settings?.services?.googleAnalyticsDisabled || false;


export const getPageViewType = (state: IState) => {
  return getObject(state)?.type || pageTypes.CHANNEL;
};

export const isMaestroBaseSite = (state: IState) => {
  const slug = getSiteSlug(state);
  return MAESTRO_BASE_SITE_SLUGS.includes(slug);
};

export const isLandingPageType = (state: IState) => getPageViewType(state) === pageTypes.LANDING;
export const isChannelPageType = (state: IState) => getPageViewType(state) === pageTypes.CHANNEL;

export const getActiveSitePanels = createSelector(
  (state: IState) => state.app.site?.features?.panels,
  (panels) => panels || EMPTY_OBJECT,
);

export const getActiveSiteOverlays = createSelector(
  (state: IState) => state.app.site?.features?.overlays,
  (overlays) => overlays || EMPTY_OBJECT,
);

export const getActiveSiteQuestActions = createSelector(
  (state: IState) => state.app.site?.features?.questActions,
  (questActions) => questActions || EMPTY_OBJECT,
);

export const getIsCurrentPageGateActive = createSelector(
  (state: IState) => !!getObject(state)?.data?.gate?.active,
  (isActive) => isActive,
);

// function syntax to resolve circular dependency with auth selectors
export const getUserMap = createSelector(
  (state: IState) => state.app.site?.users,
  (users) => users || EMPTY_OBJECT,
);

// TODO check all getPanels calls
/*
[SRE-216] CANT BE CHANGED DUE TO CONDITIONAL SELECTOR BASED-STATE, this selector isnt called on mapStateToProps only as boolean so it doesnt affects
on rerendering
*/
export const getPanels = createSelector([
  state => state,
  getObject,
], (state, channel) => {
  if (channel?.activeRenderers?.sidebar) {
    return channel.renderers?.sidebar?.items || EMPTY_ARRAY;
  }
  if (channel?.activeRenderers?.[ID_SIDEBAR]) {
    return getRenderer<{ items: any[] }>(state, ID_SIDEBAR)?.items || EMPTY_ARRAY;
  }
  if (Array.isArray(channel?.data?.sidebar) && channel?.data.sidebar.length) {
    return channel.data.sidebar;
  }
  return EMPTY_ARRAY;
});


export const getActivePanel = lazyFn(() => createSelector(
  [
    (state: IState) => state.app.activePanel,
    getActivePanelsV2,
  ],
  (currentActivePanelId, panels) => {
    // get current active panel if exists
    if (panels?.find((panel: any) => panel?.id === currentActivePanelId)) {
      return currentActivePanelId;
    }
    // get first active panel by default
    return panels?.find((panel: any) => isActivePanel(panel))?.id;
  },
));

export const getPanelIds = createSelector([getPanels], (panels) => panels.map((panel: { id: string }) => panel.id));

export const getSavedStyle = (state: IState) => state.themes.theme;

export const getAdminTheme = (state: IState) => state.themes.adminTheme;

export const getSiteStyle = lazyFn(
  () => createSelector(
    [isEditing, getPendingThemesDocs, getPreviewActiveTheme, getMockThemeRenderer, getSavedStyle],
    (isEditingAdminMode, pendingThemesDocs, previewTheme, mockTheme, savedStyle): ITheme => {
      if (!isEditingAdminMode) {
        return savedStyle || EMPTY_OBJECT;
      }

      if (mockTheme) {
        return pendingThemesDocs[mockTheme._id!] || mockTheme;
      }

      return previewTheme || savedStyle;
    },
  ),
);

export const getPageClassicThemeOptions = (state: IState) => state.app.object?.data?.theme?.classicThemeOptions;

export const getCurrentPageClassicThemeOptions = lazyFn(
  () => createSelector(
    [
      getEditingPageDoc,
      isEditing,
      getPageClassicThemeOptions,
    ],
    (doc, editing, classicThemeOptions) => {
      const documentHasDraftAccentColors = Boolean(
        doc &&
        doc.data.theme?.classicThemeOptions,
      );
      if (documentHasDraftAccentColors && editing) {
        return doc.data.theme?.classicThemeOptions;
      }
      return classicThemeOptions;
    },
  ),
);

export const getSavedPageStyle = createSelector(
  (state: IState) => getObject(state)?.style,
  (style) => style,
);

export const getThemeFromStyle = (
  siteTheme: ITheme,
  adminTheme: ITheme,
  classicThemeOptions?: IClassicThemeOptions,
  mockTheme?: ITheme | null,
): DefaultTheme => {
  const isClassicTheme = siteTheme.type === THEME_TYPES.CLASSIC;
  const overrideWithClassicThemeOptions =
    classicThemeOptions &&
    Object.keys(classicThemeOptions).length &&
    !mockTheme;
  return {
    admin: {
      colors: {
        ...adminTheme.colors,
        textContrastOnAccentPrimary: createTextContrastOnBackgroundColor(adminTheme.colors.accentPrimary),
        textContrastOnHighlightPrimary: createTextContrastOnBackgroundColor(adminTheme.colors.highlightPrimary),
      },
      typography: adminTheme.typography,
      button: adminTheme.button,
    },
    site: {
      colors: {
        ...siteTheme.colors,
        ...(EMBED && COMPOSITE_OVERLAYS && { surface1: 'transparent' }),
        ...(isClassicTheme &&
          overrideWithClassicThemeOptions && {
          accentPrimary: classicThemeOptions.accentPrimary,
          accentSecondary: classicThemeOptions.accentSecondary,
        }),
        textContrastOnAccentPrimary: createTextContrastOnBackgroundColor(
          isClassicTheme && overrideWithClassicThemeOptions
            ? classicThemeOptions.accentPrimary
            : siteTheme.colors?.accentPrimary,
        ),
        textContrastOnHighlightPrimary: createTextContrastOnBackgroundColor(siteTheme.colors?.highlightPrimary),
      },
      typography: siteTheme.typography,
      button: siteTheme.button,
    },
  };
};

export const getStyledComponentsThemeProvider = lazyFn(
  () => createSelector(
    [
      getSiteStyle,
      getAdminTheme,
      getCurrentPageClassicThemeOptions,
      getMockThemeRenderer,
    ],
    getThemeFromStyle,
  ),
);

export const getSiteCollections = createSelector(
  (state: IState) => state.app.site.collections,
  (collections) => collections,
);

export const channelSelectEnabled = (state: IState) => {
  if (getObject(state)?.data?.regions?.['channel-select']) {
    return getObject(state)?.data?.regions?.['channel-select']?.state === 'on' || false;
  }
  return state.app.site?.regions?.['channel-select']?.state === 'on' || false;
};

export const getActiveChannels = createSelector(
  (state: IState) => state.app.site.regions?.['channel-select']?.pages,
  (pages) => pages || EMPTY_ARRAY,
);

export const getWelcomeScreen = createSelector(
  (state: IState) => state.app.site.settings?.welcomeScreen,
  (welcomeScreen) => welcomeScreen || EMPTY_OBJECT,
);

export const getCurrentChannelId = (state: IState): string | undefined => getObject(state)?._id;
export const getCurrentChannelSlug = (state: IState): string | undefined => getObject(state)?.slug;

export const getCurrentChannelStreamId = (state: IState) => getObject(state)?.renderers?.livestream?.streamId;

export const channelSelectEnabledPendingChanges = (state: IState) => {
  const pendingAdminState = state.admin.pendingAdminChanges?.channelNavigation?.pendingData?.state;
  const pendingDocState = getPendingRegionsData(state)?.['channel-select']?.state;
  const pendingState = pendingAdminState || pendingDocState;
  const existingEnabledState = channelSelectEnabled(state);
  return pendingState ? pendingState === 'on' : existingEnabledState;
};

export const getSiteCollectionsMap = createSelector([getSiteCollections], (collections) => {
  const map: Record<string, any> = {};
  for (const [collection, { slug }] of Object.entries<{ slug: string }>(collections)) {
    map[slug] = collection;
  }
  return map;
});

export const getStreamingProvider = createSelector([
  (state: IState) => (
    state.app.site?.settings?.defaultStreamingProvider
  ),
], (defaultProvider = '') => defaultProvider);
export const isTimeOffsetLoaded = (state: IState) => state.app.timeOffsetLoaded;
export const getTimeOffset = (state: IState) => state.app.timeOffset;

// function syntax to resolve circular dependency with auth selectors
// DO YOU FEAR THE WRATH OF ANDY? THEN DO NOT MEMOIZE getServerTime
export function getServerTime(state: IState) {
  return state.app.timeOffsetLoaded ? Date.now() + state.app.timeOffset : null;
}
export const getChannelSelectPages = createSelector(
  // Channel-Select page is now channel specigic. But we lazy migrate so we read first from objects and then from site.
  (state: IState) => getObject(state)?.data?.regions?.['channel-select'] || state.app?.site?.regions?.['channel-select'],
  (channelSelectRegion) => channelSelectRegion?.pages,
);

export const getChannelVideoContent = createSelector(
  (state: IState) => getObject(state)?.data?.content,
  (content) => content,
);

export const getChannelScheduleContent = createSelector(
  (state: IState) => getObject(state)?.data?.content?.live?.schedule,
  (schedule) => schedule || EMPTY_ARRAY,
);

export const getObjectSlug = (state: IState) => getObject(state)?.slug;

export const getObjectId = createSelector([
  getObject,
], (obj) => obj?._id as string);

export const getLegacyCountdownData = createSelector(
  (state: IState) => getObject(state)?.data?.countdown,
  (countdown) => countdown || EMPTY_OBJECT,
);

export const getNewCountdownData = createSelector(
  (state: IState) => getObject(state)?.data?.regions?.header?.countdown,
  (countdown) => countdown || EMPTY_OBJECT,
);

export const getCountdownData = createSelector(
  [getLegacyCountdownData, getNewCountdownData],
  (legacyCountdownData, newCountdownData) => {
    return newCountdownData || legacyCountdownData;
  },
);

export const getCountdownTitle = (state: IState) => getCountdownData(state)?.title;

export const getCountdownStartTime = (state: IState) => getCountdownData(state)?.startTime;

const getLegacyChannelDoc = createSelector(
  (state: IState) => getObject(state)?.renderers?.navigation,
  (navigation) => navigation,
);


const getChannelNavigationDoc = createSelector(
  (state: IState) => getObject(state)?.data?.regions?.navigation,
  (navigation) => navigation,
);


export const getSiteNavigationDoc = createSelector(
  (state: IState) => state.app.site?.regions?.navigation,
  (navigation) => navigation || EMPTY_OBJECT,
);

export const getImageContent = createSelector(
  (state: IState) => getObject(state)?.data?.content?.offline?.image,
  (image) => image || null,
);

export const getLandingPageContent = createSelector(
  (state: IState) => getObject(state)?.data?.landingContent,
  (landingContent) => landingContent || EMPTY_ARRAY,
);

export const getNavigationDoc = createSelector(
  [getChannelNavigationDoc, getLegacyChannelDoc, getSiteNavigationDoc],
  (channelNav, legacyChannelNav, siteNav) => {
    return channelNav || legacyChannelNav || siteNav;
  },
);

export const getNavigationMap = createSelector([getNavigationDoc], (navDoc) => ({
  items: navDoc?.items || EMPTY_ARRAY,
  state: navDoc?.state || 'off',
}));

export const getHeaderData = createSelector(
  (state: IState) => state?.app?.site?.regions?.header,
  (header) => header,
);

export const getAuthenticationMap = createSelector(
  (state: IState) => state?.app?.site?.regions?.header?.authentication,
  (auth) => auth || EMPTY_OBJECT,
);

export const getSiteFooterData = createSelector(
  (state: IState) => state?.app?.site?.regions?.footer,
  (footer) => footer,
);

export const getChannelFooterData = createSelector(
  (state: IState) => getObject(state)?.data?.regions?.footer,
  (footer) => footer,
);

export const getFooterData = createSelector(
  [getChannelFooterData, getSiteFooterData],
  (channelFooter, siteFooter) => {
    return channelFooter || siteFooter;
  },
);

export const getFooterSelectors = createSelector(
  [getChannelFooterData, getSiteFooterData],
  (channelFooter, siteFooter) => {
    return [channelFooter, siteFooter];
  },
);

export const getFooterState = createSelector(
  getFooterSelectors,
  ([channelFooter, siteFooter]) =>
    channelFooter?.state || siteFooter?.state,
);

export const isFooterEnabled = (state: IState) => {
  const footerState = getFooterState(state);
  return footerState === 'on';
};

const getChannelFooterLinks = createSelector(
  getChannelFooterData,
  (footer) => {
    if (
      typeof footer?.footerLinks === 'string' &&
      footer?.footerLinks === EMPTY
    ) {
      return EMPTY_ARRAY;
    } else {
      return footer?.footerLinks;
    }
  },
);

export const getFooterLinks = createSelector(
  [getChannelFooterLinks, getSiteFooterData],
  (channelFooter, siteFooter) =>
    channelFooter ||
    siteFooter?.footerLinks ||
    EMPTY_ARRAY,
);

const getChannelSponsors = createSelector(
  getChannelFooterData,
  (footer) => {
    if (
      typeof footer?.sponsors === 'string' &&
      footer?.sponsors === EMPTY
    ) {
      return EMPTY_ARRAY;
    } else {
      return footer?.sponsors;
    }
  },
);

export const getFooterSponsors = createSelector(
  [getChannelSponsors, getSiteFooterData],
  (channelSponsors, siteFooter) => {
    const sponsors = channelSponsors || siteFooter?.sponsors || EMPTY_ARRAY;

    return sponsors.slice(0, 8) as Sponsor[];
  },
);

export const getLegacyHashtag = createSelector(
  (state: IState) => getObject(state)?.renderers?.socials?.hashtag,
  (tag) => tag,
);

export const getChannelHashtag = createSelector(
  getChannelFooterData,
  (footer) => footer?.hashtag,
);

export const getSiteHashtag = createSelector(
  getSiteFooterData,
  (footer) => footer?.hashtag,
);

export const getHashtag = createSelector(
  [getChannelHashtag, getLegacyHashtag, getSiteHashtag],
  (channel, legacyChannel, site) => {
    const options = [
      channel,
      legacyChannel,
      site,
      '',
    ];
    return options.find((option) => typeof option === 'string');
  },
);

export const getSiteSocialLinks = createSelector(
  getSiteFooterData,
  (footer) => footer?.socials);

export const getChannelSocials = createSelector(
  getChannelFooterData,
  (footer) => {
    if (
      typeof footer?.socials === 'string' &&
      footer?.socials === EMPTY
    ) {
      return EMPTY_ARRAY;
    } else {
      return footer?.socials;
    }
  },
);

export const getLegacyChannelSocials = createSelector(
  (state: IState) => getObject(state)?.renderers?.socials,
  (socials) => socials,
);

export const getLegacyChannelSocialLinks = createSelector(
  (state: IState) => getObject(state)?.renderers?.socials?.links,
  (links) => links,
);

export const getSocialLinks = createSelector(
  [
    getChannelSocials,
    getLegacyChannelSocials,
    getLegacyChannelSocialLinks,
    getSiteSocialLinks,
  ],
  (
    channelSocials,
    legacyChannelSocials,
    legacyChannelLinks,
    siteLinks,
  ) => {
    if (channelSocials || legacyChannelSocials) {
      return (channelSocials ||
        legacyChannelLinks ||
        EMPTY_ARRAY);
    }
    return (siteLinks || EMPTY_ARRAY) as Social[];
  },
);


export const getSiteTweetMessage = createSelector(
  getSiteFooterData,
  (footer) => footer?.tweetMessage,
);

export const getLegacyChannelTweetMessage = createSelector(
  (state: IState) => getObject(state)?.renderers?.socials?.tweetMessage,
  (message) => message,
);

export const getChannelTweetMessage = createSelector(
  getChannelFooterData,
  (footer) => footer?.tweetMessage,
);

export const getTweetMessage = createSelector(
  [getChannelTweetMessage, getLegacyChannelTweetMessage, getSiteTweetMessage],
  (channelMessage, legacyChannelMessage, siteMessage) =>
    channelMessage || legacyChannelMessage || siteMessage || '',
);

const getRawAuths = createSelector(
  (state: IState) => state.app.site?.regions?.header?.authentication,
  (auth) => auth,
);

export const getCardId = createSelector(
  (state: IState) => state?.app?.object?.renderers?.sidebar?.cardId,
  (cardId?: string) => cardId,
);

export const ENABLE_SQUARE_ENIX_AUTH = ['andy-presentation', 'square-enix'].includes(SITE_SLUG);

export const getAuths = createSelector([getRawAuths], (auths) => {
  return {
    ...auths,
    squareenix: ENABLE_SQUARE_ENIX_AUTH,
  };
});

export const isSiteLoginEnabled = (state: IState) =>
  Object.values(getAuths(state)).some((enabled) => enabled) || FORCE_ENABLE_MAESTRO_LOGIN;

export const getPlayerData = createSelector(
  (state: IState) => state.app.site?.regions?.player,
  (player) => player,
);

export const getPlayerSocials = createSelector(
  (state: IState) => state.app.site?.regions?.player?.socials,
  (socials: IPlayerSocials) => socials || EMPTY_OBJECT,
);

export const getIsPlayerFacebookShareActive = createSelector(
  getPlayerSocials,
  (socials) => socials.facebook?.active || false,
);

export const getIsPlayerTwitterShareActive = createSelector(
  getPlayerSocials,
  (socials) => socials.twitter?.active || false,
);

export const getShowVideoTitleFlag = createSelector(
  (state: IState) => state.app.site?.regions?.player?.showVideoTitle,
  (title) => title,
);

export const getShowVideoUrlFlag = (state: IState) => {
  const flag = getPlayerData(state)?.showVideoUrl;
  return typeof flag === 'boolean' ? flag : true;
};

export const getShowVideoEmbedFlag = (state: IState) => {
  const flag = getPlayerData(state)?.showVideoEmbed;
  return typeof flag === 'boolean' ? flag : true;
};

export const getShowYoutubeNoCookieFlag = lazyFn(
  () => createSelector(
    getPlayerData,
    (player) => Boolean(player?.showYoutubeNoCookie), // return false (off) by default
  ),
);

// NavigationV2
export const getParentAndChildSlug = (state: IState) => {
  const {
    location: { pathname },
  } = state.router;
  // grabs the chars between literal forward slashes: /parent/chid => [/parent, /child]
  const navSlugPattern = /\/([^\/]+)/g;
  const match = pathname.match(navSlugPattern);
  if (!match) return { parentSlug: '', childSlug: '' };

  const [parentSlug, childSlug] = [match[0], match[1]];
  return { parentSlug, childSlug };
};

export const getRedirectUrl = (state: IState) => {
  const {
    location: { pathname },
  } = state.router;
  return `${window.location.protocol}//${window.location.host}${BASE_PATH}${pathname}`;
};

export const isMaestroLoginEnabled = (state: IState) => Boolean(FORCE_ENABLE_MAESTRO_LOGIN || getAuths(state)?.maestro);

export const onChannel = (state: IState) =>
  getObject(state)?.type === 'channel' || getObject(state)?.type === 'landing';

export const getContentWidth = (state: IState) => {
  const [isAdmin, editModeOn, broadcastModeOn, sidebarVisible, mobile, deviceWidth, sidebarWidth, adminbarWidth] = [
    isUserAdmin(state),
    isEditMode(state),
    isBroadcastMode(state),
    isSidebarVisible(state),
    isMobileLayout(state),
    getWidth(state),
    getSidebarWidth(state),
    getAdminBarWidth(state),
  ];
  const adminBarVisible = isAdmin && !(editModeOn || broadcastModeOn);
  return (
    deviceWidth - (
      // content width is same as device width on mobile
      (mobile ? 0 : 1) * (
        // account for sidebar width
        (sidebarWidth * (sidebarVisible ? 1 : 0)) +
        // account for admin/broadcast bar width when admin
        (adminbarWidth * (adminBarVisible ? 1 : 0))
      )
    )
  );
};

export const fontUrl = `https://${LEGACY_DOMAIN}/pkg/font/1/public`;

export const getFonts = createSelector([getStyledComponentsThemeProvider], (theme) =>
  deduped([theme.site.typography?.body, theme.site.typography?.headline])
    .filter((name) => name)
    .map((name) => ({
      baseUrl: `${fontUrl}/${name}`,
      name: name as string,
    })),
);

export const getAdminFonts = createSelector([getStyledComponentsThemeProvider], (theme) =>
  deduped([theme.admin.typography.body, theme.admin.typography.headline])
    .filter((name) => name)
    .map((name) => ({
      baseUrl: `${fontUrl}/${name}`,
      name,
    })),
);

export const getDefaultLogo = createSelector(
  (state: IState) => state.app.site?.regions?.header.defaultLogo,
  (logo) => logo,
);
export const getLegacySiteLogo = createSelector([getHeaderData], (header) => ({
  logoLink: header?.logoLink,
  logoMobile: header?.mobileLogo,
  logoWeb: header?.logo,
}));

// deprecated
export const getSiteLogo = createSelector(
  [getDefaultLogo, getLegacySiteLogo],
  (defaultLogo, legacySiteLogo) => defaultLogo || legacySiteLogo,
);

export const getPageLogo = createSelector(
  (state: IState) => getObject(state)?.data?.artwork?.header,
  (header) => ({
    logoLink: header?.logoLink,
    logoMobile: header?.logo,
    logoTitle: header?.logoTitle,
    logoWeb: header?.logo,
  }),
);

export const hasPageLogoImage = (state: IState) =>
  getPageLogo(state)?.logoWeb && getPageLogo(state)?.logoWeb !== NO_IMAGE;

export const getLogo = (state: IState) => getPageLogo(state);

export const getLogoLink = (state: IState) => getPageLogo(state)?.logoLink;

export const getLogoTitle = (state: IState) => getLogo(state)?.logoTitle;

// deprecated
export const getSiteLogoMobile = (state: IState) => getSiteLogo(state)?.logoMobile;

// deprecated
export const getSiteLogoWeb = (state: IState) => getSiteLogo(state)?.logoWeb;

// deprecated
export const getSiteLogoTitle = (state: IState) => getSiteLogo(state)?.logoTitle;

// deprecated
export const getSiteLogoLink = (state: IState) => getSiteLogo(state)?.logoLink;

export const getLogoImage = (state: IState) => {
  const logoObject = getLogo(state);
  if (isMobileLayout(state)) {
    return logoObject?.logoMobile || logoObject?.logoWeb;
  }
  return logoObject?.logoWeb;
};

const timeIsInPast = (timeString: string) => Date.now() > (Date.parse(timeString) || 0);

export const hasHeader = (state: IState) => {
  const countdownData = getCountdownData(state);
  const embed = isVideoEmbed(state) || isTheaterEmbed(state) || isEmbed(state);
  const logo = getLogo(state);
  const videoOnlyMode = isVideoOnlyMode(state);
  const countdownStartTime = countdownData?.startTime;
  const { items, state: navState } = getNavigationMap(state);

  return (
    !embed &&
    !videoOnlyMode &&
    Boolean(
      logo || (navState === 'on' && items.length > 0) || (countdownStartTime && !timeIsInPast(countdownStartTime)),
    )
  );
};

// insights site stuff
// TODO: figure out what we're doing for id and versions
export const getSite = createSelector(
  (state: IState) => state.app.site,
  (site) => site,
);
export const getSiteDomain = (state: IState): string | undefined => state.app.site?.settings?.domain?.url;
export const getSiteId = (state: IState) => state.app.site?._id;
export const getSitePackageId = (state: IState) => `${state.app.site?.package?.id}-sapphire`;
export const getSitePackageVersion = (state: IState) => `${state.app.site?.package?.version}-sapphire`;
export const getSiteProfileImages = createSelector(
  (state: IState) => state.app.site?.settings?.userProfiles?.profileImages,
  (profileImages) => profileImages || defaultProfileImages,
);
export const getSiteProfileSettings = createSelector(
  [(state: IState) => state.app.site?.settings?.userProfiles?.changeName, getSiteProfileImages],
  (name, images) => ({
    changeName: name || false,
    profileImages: images || defaultProfileImages,
  }),
);
export const getSiteSlug = (state: IState) => state.app.site?.slug;

export const getSavedSettingsDefaultThemeId = (state: IState) => state.app.site?.settings.defaultThemeId;

export const getSettingsIcons = createSelector(
  (state: IState) => state.app.site?.settings?.icons,
  (icons) => icons || EMPTY_OBJECT,
);
export const getNewAdminAllowed = (state: IState) => state.app.site?.settings?.allowNewAdmin || false;
export const getCodeInjections = createSelector(
  (state: IState) => state.app.site?.settings?.codeInjection,
  (codeInjection) => codeInjection,
);
export const getCustomTextModal = createSelector(
  (state: IState) => state.app.site?.settings?.login,
  (login) => login || defaultCustomTextModal,
);
export const getAccountFound = createSelector(
  (state: IState) => state.auth.foundAccount,
  (account) => account,
);
export const getCustomerProfile = createSelector(
  (state: IState) => state.app.site?.settings?.customerProfile,
  (profile) => profile || EMPTY_OBJECT_WITH_EMPTY_NAME,
);
export const getPage = createSelector(
  getObject,
  (obj) => obj,
);
export const getPageCollection = createSelector(
  (state: IState) => getObject(state)?.collection,
  (coll) => coll,
);
export const getPageId = (state: IState) => getObject(state)?._id;
export const getPageName = (state: IState) => getObject(state)?.data?.name || getObject(state)?.seo?.title;
export const getPageSlug = (state: IState) => getObject(state)?.slug;
export const getPageType = (state: IState) => getObject(state)?.type;
export const getPageBaseLanguage = (state: IState) => getObject(state)?.baseLanguage || 'en-US';
export const getPages = createSelector(
  (state: IState) => state.app.site?.regions?.['channel-select'].pages,
  (pages) => pages,
);
export const getServices = createSelector(
  (state: IState) => state.app.site?.settings?.services,
  (services) => services,
);
export const getTaxes = createSelector(
  (state: IState) => state.app.site?.tax,
  (tax) => tax,
);
export const getMarketingOptin = createSelector(
  (state: IState) => state.app.site?.settings?.login?.marketingOptin,
  (optin) => optin,
);
export const getMarketingOptinLabel = createSelector(
  (state: IState) => state.app.site?.settings?.login?.marketingOptinLabel,
  (label) => label,
);
export const getFacebookAppId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.facebookAppId,
  (facebookId) => facebookId,
);
export const getFacebookPixelId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.facebookPixelId,
  (facebookPixelId) => facebookPixelId,
);
export const getFaceit = createSelector(
  (state: IState) => state.app.site?.settings?.services?.faceit,
  (faceIt) => faceIt,
);
export const getGoogleAnalyticsId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.googleAnalyticsId,
  (googleAnalyticsId) => googleAnalyticsId,
);

export const get3rdPartySavedData = createSelector(
  [getFacebookAppId, getFacebookPixelId, getFaceit, getGoogleAnalyticsId],
  (facebookAppId, facebookPixelId, faceit, googleAnalyticsId) => ({
    facebookAppId,
    facebookPixelId,
    faceit,
    googleAnalyticsId,
  }),
);

export const getLoaded = (state: IState) => Boolean(state.app.site);
export const getScheduledEvents = createSelector(
  (state: IState) => getObject(state)?.data?.content?.live?.schedule,
  (schedule) => schedule,
);

export const isChannelSelectActive = (state: IState) =>
  channelSelectEnabled(state) &&
  !isLandingPageType(state) &&
  !isMobileLayout(state) &&
  !isEmbed(state) &&
  !isVideoOnlyMode(state);

export const isFooterActive = (state: IState) =>
  !isEmbed(state) && !isTheaterEmbed(state) && !isVideoEmbed(state) && !isVideoOnlyMode(state);

export const getCustomVideoOverlayUrl = (state: IState) => {
  const { object }: any = state.app;
  const { enabled = false, url = null } = object?.data?.customVideoOverlay! || EMPTY_OBJECT;
  return (enabled && typeof url === 'string' && url.trim()) || null;
};

export const showConcurrents = createSelector(
  (state: IState) => state.app.site.settings?.showConcurrents,
  (showconcurrents) => showconcurrents || showconcurrents === undefined,
);

export const getMinimumConcurrents = (state: IState) => {
  return state.app.site?.settings?.minimumConcurrents || 0;
};

export const getSavedPageArtwork = createSelector(
  (state: IState) => getObject(state)?.data?.artwork,
  (artWork) => artWork,
);

export const getPageArtwork = lazyFn(
  () => createSelector(
    [getMockArtworkRenderer, getSavedPageArtwork],
    (mockArtwork, savedPageArtwork) => mockArtwork || savedPageArtwork,
  ),
);

export const getBackgroundImage = (state: IState) => {
  const theme = getSiteStyle(state);
  const isMobile = isMobileLayout(state);
  const artwork = getPageArtwork(state);

  if (theme._id) {
    if (theme.mobileBackground && isMobile) {
      return theme.mobileBackground;
    }
    if (theme.desktopBackground) {
      return theme.desktopBackground;
    }
  }

  if (artwork) {
    if (artwork.video?.kind === 'ad') {
      return null;
    }
    if (typeof artwork.background === 'string') {
      // TODO: Is this legacy?
      return artwork.background as string;
    }
  }

  return null;
};

export const getParentDomains = (state: IState) => state.app.parentDomains;

export const getVideoContentHeight = createSelector(
  [hasHeader, getDeviceHeight],
  (header, height: number) => {
    let deviceHeight = height;

    deviceHeight -= Number.parseInt(SPACING_MEDIUM, 10); // top padding
    if (header) {
      deviceHeight -= HEADER_HEIGHT; // header height
      deviceHeight -= Number.parseInt(SPACING_MEDIUM, 10); // bottom margin
    }

    deviceHeight -= Number.parseInt(SPACING_SMALL, 10); // video margin top

    deviceHeight -= CHANNEL_SELECT_TOOLTIP_HEIGHT_PX;
    deviceHeight -= CHANNEL_SELECT_HEIGHT_PX;

    deviceHeight -= Number.parseInt(SPACING_LARGE, 10); // bottom padding

    return deviceHeight;
  },
);

export const getActivePlayerOverride = createSelector(
  (state: IState) => state.app.site?.settings?.playerOverride,
  (playerOverride) => playerOverride || null,
);

export const getCurrentPageId = (state: IState) => getManagingPageId(state) || getObjectId(state);

export const getCurrentPageDraftDoc = lazyFn(
  () => createSelector(
    [getPendingPageDocs, getCurrentPageId],
    (pendingPageDraftDocs, currentPageId) => pendingPageDraftDocs[currentPageId] || null,
  ),
);

export const isCurrentPageHome = lazyFn(
  () => createSelector(
    [getDraftHomeId, getCurrentPageId],
    (homeId, currentPageId) => homeId === currentPageId,
  ),
);

export const getCurrentPageType = (state: IState) => {
  const unvalidatedPageType = getManagingPageType(state);
  if (Object.values(pageTypes).includes(unvalidatedPageType)) {
    return unvalidatedPageType;
  }
  return pageTypes.CHANNEL;
};

export const getSiteContract = (state: IState) => state.app.site.contract;

export interface IPlayerSocials {
  facebook?: {
    active: boolean;
  };
  twitter?: {
    active: boolean;
  };
}

export const isImageMode = (state: IState) => {
  const pendingOfflineContent = getPendingOfflineContent(state);
  const editMode = isEditMode(state);
  if (editMode && pendingOfflineContent) {
    return pendingOfflineContent?.mode === 'image';
  }
  return getObject(state)?.data?.content?.offline?.mode === 'image';
};

export const getShowMobileChannelSelector = createSelector([
  (state: IState) => state.app?.showMobileChannelSelector || false,
  isLandscape,
], (showMobileChannelSelector, landscape) => (
  !landscape && showMobileChannelSelector
));

export const getShouldCloseChannelSelectOnOutsideClick = (state: IState) => state.app?.shouldCloseChannelSelectOnOutsideClick || false;

export const isChannelSelectorSidebarButtonVisible = createSelector([
  getChannelSelectPages,
  channelSelectEnabled,
  isLandingPageType,
  isMobileLayout,
  isGateActive,
], (channelSelectPages, shouldRenderChannelSelect, isLanding, isMobile, channelGateActive) => (
  !channelGateActive && !(isMobile && isLanding) && !isMobile && shouldRenderChannelSelect && !!channelSelectPages?.length
));

export const isChannelSelectorHeaderButtonVisible = createSelector([
  getChannelSelectPages,
  channelSelectEnabled,
  isChannelSelectorSidebarButtonVisible,
], (
  channelSelectPages,
  shouldRenderChannelSelect,
  channelSelectorSidebarVisible,
) => (
  !channelSelectorSidebarVisible &&
  shouldRenderChannelSelect &&
  !!channelSelectPages?.length
));

export const getSiteTags = (state: IState): string[] => state.app.site?.tags || [];

export const getGateLogo = createSelector(
  [getCurrentGateData, getLogoImage],
  (gateData, logoImage) => gateData?.gate?.logoUrl || logoImage,
);

export const getGateLogoTitle = createSelector(
  [getCurrentGateData, getLogoTitle],
  (gateData, logoTitle) => gateData?.gate?.logoTitle || logoTitle,
);

export const getGateLogoLink = createSelector(
  [getCurrentGateData, getLogoLink],
  (gateData, logoLink) => gateData?.gate?.logoLink || logoLink || '',
);

export const getGateLogoData = createSelector([
  getGateLogo,
  getGateLogoTitle,
  getGateLogoLink,
], (
  logoUrl,
  logoTitle,
  logoLink,
) => ({
  logoUrl,
  logoTitle,
  logoLink,
}));

export const getGate = createSelector(
  (state: IState) => state.app.object,
  (page) => page?.data?.gate,
);

export const getGateInformation = createSelector(
  getGate,
  gate => gate?.gate,
);

export const getSavedSubscriptionGateBackgroundMobileImage = createSelector(
  getGateInformation,
  (gateInformation) => gateInformation?.subscriptionGateBackgroundMobile,
);

export const getSavedSubscriptionGateBackgroundWebImage = createSelector(
  getGateInformation,
  (gateInformation) => gateInformation?.subscriptionGateBackgroundWeb,
);
export const gateSavedGateBackground = createSelector(
  getCurrentGateData,
  (gateData) => gateData.gate?.background,
);

export const getStudioConfigData = createSelector(
  (state: IState) => state.app.site.settings?.login?.privacy,
  (state: IState) => state.customerProfile.support.URL,
  (privacyUrl, supportUrl) => hash({ privacyUrl, supportUrl }),
);

export const getChannelLivestreamStatus = createSelector(
  getObject,
  (object) => object.renderers?.livestream?.status,
);

export const getStreamingProviderAccountId = createSelector(
  getSite,
  (site) => site.settings?.streamingProviderAccountId,
);

export const getDefaultStreamingProvider = createSelector(
  getSite,
  (site) => site.settings?.defaultStreamingProvider,
);

export const isShowingLeavingStudioModal = createSelector(
  (state: IState) => state.app,
  (app) => app.shouldShowLeavingStudioModal,
);

export const getDefaultLanguage = lazyFn(
  () => createSelector(
    getSite,
    (site) => site?.settings?.defaultLanguage,
  ),
);

export const getShowUserLanguagePicker = lazyFn(
  () => createSelector(
    getSite,
    (site) => site?.settings?.showUserLanguagePicker,
  ),
);

export const isPagePublic = createSelector(
  [getObject], (page): boolean => {
  return !page.data.private;
});

export const isPageVisible = createSelector(
  [isUserAdmin, isPagePublic], (isAdmin, isPublic): boolean => {
  return isAdmin || isPublic;
});

export const getActiveMobileMenu = (state: IState) => state.app.mobileNavMenu;

const isNavigationEnabled = (object: IObject) => Boolean(object?.data?.regions?.navigation?.state === 'on');

export const getPageNavigationEnabled = createSelector(
  getCurrentPageDraftDoc,
  getObject,
  (pendingPage, page) => {
    return isNavigationEnabled(pendingPage || page);
  },
);

export const hasBothYtOrTwitchAndUploadedVideosOnPageContent = lazyFn(() =>
  createSelector(
    [(state: IState) => state, getEditingPageDoc],
    (state, doc): boolean => {
      if (doc.type === pageTypes.LANDING) {
        return landingPageHasYTorTwitchAndUploadedContent(doc, state);
      }
      return false;
    },
  ),
);

export const landingPageHasYTorTwitchAndUploadedContent = (
  doc: IObject,
  state: IState,
): boolean => {
  const landingContent = doc?.data?.landingContent;
  if (!landingContent || landingContent.length < 2) {
    return false;
  }
  let hasYTOrTwitchContent: boolean = false;
  let hasUploadedContent: boolean = false;
  const onlyVideoContent = landingContent.filter((content) => [VIDEO_SPOTLIGHT, PLAYLIST].includes(content.kind));
  for (const content of onlyVideoContent) {
    let currentHasYtOrTwitch: boolean = false;
    if (content.kind === VIDEO_SPOTLIGHT) {
      currentHasYtOrTwitch = Boolean(content.data && videoHasYoutubeOrTwitch(content.data.videoId, state));
    }
    if (content.kind === PLAYLIST) {
      currentHasYtOrTwitch =  Boolean(content.playlist && playlistHasYoutubeOrTwitch(content.playlist._id, state));
    }
    if (currentHasYtOrTwitch) {
      hasYTOrTwitchContent = true;
    }
    if (!currentHasYtOrTwitch) {
      hasUploadedContent = true;
    }
    if (hasYTOrTwitchContent && hasUploadedContent) {
      return true;
    }
  }
  return hasYTOrTwitchContent && hasUploadedContent;
};
