import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { StripeError, PaymentMethod, Token } from '@stripe/stripe-js';
import { getPrimaryToken, isUserAdmin } from 'services/auth/selectors';
import { LOG_OUT, LOG_IN_SUCCESS, checkGate } from 'services/auth/actions';
import IState from 'services/state';
import { SITE_ID, REFERER_URL } from 'config';
import { dismissModal, showAdminErrorModal, showModal } from 'services/modals/actions';
import { ModalKinds } from 'services/modals/types';

import {
  COMPLETE_PAYMENT_PROCESS,
  ISubmitPaymentAction,
  addPaymentMethod,
  setPaymentMethods,
  updateWallet,
  setTaxRate,
  resetBillingReducer,
  stopPaymentsLoading,
  SUBMIT_DEFAULT_PAYMENT_METHOD,
  SUBMIT_PAYMENT_METHOD,
  SUBMIT_PAYMENT,
  REMOVE_PAYMENT,
  REMOVE_PAYMENT_CONFIRM,
  GET_WALLET_DATA,
  IRemovePaymentAction,
  IRemovePaymentConfirmAction,
  ISubmitPaymentMethodAction,
  ISubmitDefaultPaymentMethodAction,
  setDefaultPaymentMethod,
  WATCH_ENTITLEMENT_ACCESS,
  IWatchEntitlementAccessAction,
  FETCH_TAX_RATE,
  CHECK_APPLE_PAY_VERIFY,
  completePaymentProcess,
  ICancelSubscriptionAction,
  CANCEL_SUBSCRIPTION,
  setEntitlementLoading,
  EDIT_PAYMENT_METHOD,
  IEditPaymentMethodAction,
  completeEditPaymentMethodProcess,
  getWalletData as getWalletDataAction,
  updateViewerServiceChargeRate,
  GET_CONTRACT_DATA,
  updateContractData,
  setBillingInfoError,
  updatePlanData,
  DELETE_SOURCE,
  IDeleteSourceAction,
  deleteSourceComplete,
  IPurchasePlanAction,
  purchasePlanComplete,
  purchasePlanError,
  PURCHASE_PLAN,
  setNativeCurrency,
  IFetchTaxRateAction,
  IUpgradePlanAction,
  upgradePlanComplete,
  upgradePlanError,
  UPGRADE_PLAN,
  clearZipcode,
} from './actions';
import {
  removePayment,
  getPayments,
  submitPaymentMethod,
  submitPayment,
  submitDefaultPaymentMethod,
  getStripePaymentMethod,
  getTaxRate,
  applePayVerify,
  cancelSubscription,
  submitEditPaymentMethod,
  getWalletData,
  getViewerServiceCharge,
  getContractData,
  getPlanData,
  removeAdminPayment,
  getAdminPayments,
  submitAdminPaymentMethod,
  getStripeCardToken,
  submitAdminDefaultPaymentMethod,
  submitEditAdminPaymentMethod,
  purchasePlan,
  getStripePaymentMethodWithoutBillingInfo,
  fetchNativeCurrency,
  changePlan,
  IChangePlanParams,
} from './api';
import {
  IWallet,
  IWalletData,
  ITaxRate,
  IPurchaseRequest,
  IEditPaymentMethodParams,
  IContract,
  IChargeResult,
  isBillingInfo,
} from './models';
import {
  getIdempotencyKey,
  getWallet as getWalletSelector,
  getBillingInfo,
  getStripePaymentMethods,
  getContract,
  getNativeCurrency,
} from './selectors';
import Plan from 'models/IPlan';
import { subscribe } from 'services/realtime/actions';
import { isListening } from 'services/realtime/selectors';
import { getParentOrigin } from 'services/iframe/selectors';
import { deleteSingleUseSource } from 'services/shopify/api';
import { removeSingleUseSourceCard } from 'services/shopify';
import { getPageSlug } from 'services/app/selectors';
import { getCountryCode, SET_COUNTRY_CODE_SAGA_ACTION } from 'services/insights';

// fired on any LOG_OUT
export const resetBillingSaga = function* () {
  yield put(resetBillingReducer());
};

export const getWalletDataSaga = function* () {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const userAdmin: boolean = isUserAdmin(state);
  if (!accessToken) {
    return;
  }

  try {
    if (userAdmin) {
      // yield call(getContractDataSaga);
      return;
    }
    const walletData: IWalletData = yield call(getWalletData, accessToken, SITE_ID, userAdmin);
    if (walletData) {
      yield put(updateWallet(walletData.wallet));
      yield put(setDefaultPaymentMethod(walletData.defaultCardId));
      yield put(setPaymentMethods(walletData.cards));
    }
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.error(err);
  }
};

export const fetchTaxRateSaga = function* ({ payload }: IFetchTaxRateAction) {
  const state: IState = yield select();
  let billingInfo = getBillingInfo(state);

  if (!isBillingInfo(billingInfo)) {
    const paymentMethod = payload;
    if (!paymentMethod || !paymentMethod.billing_details.address) {
      return;
    }
    const { country, state: stateCode, postal_code } = paymentMethod.billing_details.address;

    if (
      !country ||
      !stateCode ||
      !postal_code
    ) {
      return;
    }

    billingInfo = {
      countryCode: country,
      stateCode,
      postalCode: postal_code,
      complete: true,
      error: null,
      markDefaultPaymentMethod: false,
      name: paymentMethod.billing_details.name || '',
    };
  }

  const accessToken: string | null = getPrimaryToken(state);
  if (!accessToken) {
    return;
  }
  try {
    const taxRate: ITaxRate = yield call(getTaxRate, accessToken, billingInfo, SITE_ID);
    yield put(setTaxRate(taxRate));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error retrieving taxrate');
    yield put(showAdminErrorModal(`Invalid Zip/Postal code.`));
    yield put(setTaxRate({ taxRate: null }));
    yield put(clearZipcode());
  }
};

export const updatePaymentsSaga = function* () {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const wallet = getWalletSelector(state);

  if (!accessToken || !wallet) {
    return;
  }

  try {
    const payments: PaymentMethod[] = yield call(getPayments, accessToken, SITE_ID, wallet._id);
    yield put(setPaymentMethods(payments));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error('could not update payments');
  }
};

export const submitPaymentSaga = function* ({ payload }: ISubmitPaymentAction) {
  const state: IState = yield select();
  const accessToken = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }
  let billingInfo = getBillingInfo(state);

  const idempotencyKey = getIdempotencyKey(state);

  if (!payload.sku) {
    return;
  }

  const { paymentMethod: payloadPaymentMethod, presentmentCurrency } = payload;
  let result: { error?: StripeError; paymentMethod?: PaymentMethod } | null = null;

  // didn't selected previously created payment method or is not using payment request button (google pay/apple pay)
  if (!payloadPaymentMethod && payload.element && payload.stripe) {
    try {
      const name = state.auth.account?.name || '';
      result = yield call(
        getStripePaymentMethod,
        payload.element,
        payload.stripe,
        {
          ...billingInfo,
          name: billingInfo.name || name,
        },
      );
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e, 'error retrieving payment method from stripe');
      yield put(setBillingInfoError('Payment unsuccessful, please update your card details and try again'));
    }
  } else {
    result = { paymentMethod: payloadPaymentMethod };
  }
  if (result && result.paymentMethod) {
    const { paymentMethod } = result;
    const parentOrigin = getParentOrigin(state);
    const pageSlug = getPageSlug(state);
    const referer = {
      origin: parentOrigin || REFERER_URL,
      pageSlug,
    };

    let token: Token | null = null;

    if (payload.element && payload.stripe) {
      const { token: stripeCardToken } = yield call(getStripeCardToken, payload.element, payload.stripe, billingInfo);
      token = stripeCardToken;
    }

    if (!isBillingInfo(billingInfo) && paymentMethod.billing_details.address) {
      const { country, state: stateCode, postal_code } = paymentMethod.billing_details.address;
      if (
        !country ||
        !postal_code
      ) {
        return;
      }

      billingInfo = {
        countryCode: country,
        stateCode: stateCode || '',
        postalCode: postal_code,
        complete: true,
        error: null,
        markDefaultPaymentMethod: false,
        name: paymentMethod.billing_details.name || '',
      };
    }

    const paymentPayload: IPurchaseRequest = {
      billingInfo,
      bundleId: payload.bundleId,
      idempotencyKey,
      paymentMethod,
      presentmentCurrency,
      referer,
      savePaymentMethod: payload.savePaymentMethod,
      sku: payload.sku,
      token,
    };

    try {
      yield call(submitPayment, accessToken, SITE_ID, paymentPayload);
      yield put(dismissModal(ModalKinds.subscriptionPurchase));
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e, 'error submitting payment method');
      yield put(setBillingInfoError('Payment unsuccessful, please update your card details and try again'));
    }
  } else {
    // tslint:disable-next-line: no-console
    console.error(result?.error?.message, 'error submitting payment');
    yield put(setBillingInfoError('Payment unsuccessful, please update your card details and try again'));
  }
  yield put(checkGate());
  yield put(completePaymentProcess());
};

export const submitPaymentMethodSaga = function* ({ payload }: ISubmitPaymentMethodAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const wallet: IWallet | null = getWalletSelector(state);
  const contract = getContract(state);
  const currentStripePaymentMethods = getStripePaymentMethods(state);
  const isAdmin: boolean = isUserAdmin(state);
  if (!accessToken) {
    return;
  }
  const billingInfo = getBillingInfo(state);
  if (!billingInfo) {
    return;
  }

  try {
    const { billingData, element, stripe, makePrimaryPaymentMethod = false } = payload;
    const { paymentMethod } = yield call(getStripePaymentMethod, element, stripe, billingInfo);
    const isPaymentMethodRepeated = currentStripePaymentMethods.some((pm) => pm.id === paymentMethod.id);

    if (!paymentMethod) {
      return;
    }

    let success: boolean;
    if (!isAdmin) {
      if (!wallet) {
        return;
      }

      const { token } = yield call(getStripeCardToken, element, stripe, billingInfo);

      const submitPaymentMethodPayload = {
        shopifyBillingData: billingData,
        markAsDefault: makePrimaryPaymentMethod,
        paymentMethod,
        token,
      };
      const result: IChargeResult = yield call(
        submitPaymentMethod,
        accessToken,
        SITE_ID,
        wallet._id,
        submitPaymentMethodPayload,
      );
      success = result.success;
    } else {
      // user is an admin
      if (!contract || !contract.stripeCustomerId || isPaymentMethodRepeated) {
        return;
      }
      const result: { success: boolean } = yield call(
        submitAdminPaymentMethod,
        accessToken,
        contract.stripeCustomerId,
        SITE_ID,
        paymentMethod,
      );
      success = result.success;
    }
    if (success) {
      yield put(addPaymentMethod(paymentMethod));
      yield put(setDefaultPaymentMethod(paymentMethod.id));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error submitting payment method');
    yield put(stopPaymentsLoading());
    yield put(showAdminErrorModal('NEW_PAYMENT_METHOD_ERROR',  e?.response?.data?.message?.details || 'Unknown error'));
  }
  // yield put(clearBillingInfo());
  yield put(completePaymentProcess());
  yield put(getWalletDataAction());
};

export const removePaymentSaga = function* ({ payload }: IRemovePaymentAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const userAdmin: boolean = isUserAdmin(state);
  if (!accessToken) {
    return;
  }

  try {
    if (!userAdmin) {
      const wallet: IWallet | null = getWalletSelector(state);
      if (!wallet) {
        return;
      }
      const result: { defaultPaymentMethod: string, success: boolean } = yield call(
        removePayment,
        accessToken,
        SITE_ID,
        wallet._id,
        payload,
      );
      yield put(setDefaultPaymentMethod(result.defaultPaymentMethod));
      if (!result.success) {
        throw new Error('error deleting payment method');
      }
    } else {
      // admin user
      const result: { defaultPaymentMethod: string, success: boolean } = yield call(
        removeAdminPayment,
        accessToken,
        SITE_ID,
        payload,
      );
      yield put(setDefaultPaymentMethod(result.defaultPaymentMethod));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error deleting payment method');
    yield put(showAdminErrorModal(`Unable to delete payment method`));
    // if error removing a payment then we should re fetch payments
    yield call(updatePaymentsSaga);
  }
};

export const removePaymentConfirmSaga = function* ({ payload }: IRemovePaymentConfirmAction) {
  const state: IState = yield select();
  const isAdmin: boolean = isUserAdmin(state);
  const paymentMethods = getStripePaymentMethods(state);
  if (isAdmin) {
    if (paymentMethods.length === 1) {
      // dont allow it
      yield put(
        showModal({
          data: {
            promptStringKey: 'REMOVE_PAYMENT_METHOD_ERROR',
          },
          kind: ModalKinds.errorModal,
        }),
      );
      return;
    } else {
      // remove
      yield put(
        showModal(
        {
          data: {
            onConfirmClick: payload.removeFunction,
            promptStringKey: 'REMOVE_PAYMENT_METHOD_CONFIRM',
          },
          kind: ModalKinds.confirmation,
        },
      ));
      return;
    }
  }

  if (payload.id) {
    yield put(
      showModal({
        data: {
          promptStringKey: 'REMOVE_PAYMENT_METHOD_ERROR',
        },
        kind: ModalKinds.errorModal,
      }),
    );
    return;
  }
  yield put(
    showModal(
    {
      data: {
        onConfirmClick: payload.removeFunction,
        promptStringKey: 'REMOVE_PAYMENT_METHOD_CONFIRM',
      },
      kind: ModalKinds.confirmation,
    },
  ));
};

export const submitDefaultPaymentMethodSaga = function* ({ payload }: ISubmitDefaultPaymentMethodAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const wallet: IWallet | null = getWalletSelector(state);
  const contract = getContract(state);
  const isAdmin = isUserAdmin(state);
  if (!accessToken) {
    return;
  }

  try {
    if (!isAdmin) {
      // regular user
      if (!wallet) {
        // tslint:disable-next-line: no-console
        console.warn('contract not found');
        return;
      }

      yield put(setDefaultPaymentMethod(payload));
      const result: IChargeResult = yield call(
        submitDefaultPaymentMethod,
        accessToken,
        SITE_ID,
        wallet._id,
        payload,
      );
      if (!result.success && result.sourceId) {
        yield put(stopPaymentsLoading());
        throw new Error('error updating default payment method');
      }

    } else {
      // admin
      if (!contract || !contract.stripeCustomerId) {
        // tslint:disable-next-line: no-console
        console.warn('contract not found');
        return;
      }
      yield put(setDefaultPaymentMethod(payload));
      const result: IChargeResult = yield call(
        submitAdminDefaultPaymentMethod,
        accessToken,
        SITE_ID,
        contract.stripeCustomerId,
        payload,
      );
      if (!result.success) {
        yield put(stopPaymentsLoading());
        throw new Error('error updating default payment method');
      }

    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error updating default payment method');
    yield put(stopPaymentsLoading());
    yield put(showAdminErrorModal(`Unable to update default payment method`));
  }
};

export const watchEntitlementAccessSaga = function* ({ payload }: IWatchEntitlementAccessAction) {
  const state: IState = yield select();
  if (!isListening(state, 'entitlementaccess', payload)) {
    yield put(subscribe(`entitlementaccess/${payload}`));
  }
};

const checkApplePayVerify = function* () {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);

  try {
    yield call(applePayVerify, primaryToken, SITE_ID);
  } catch (err) {
    yield put(showAdminErrorModal('Error: Domain failed the apple pay verification'));
  }
};

export const cancelSubscriptionSaga = function* ({ payload }: ICancelSubscriptionAction) {
  const state: IState = yield select();
  const accessToken = getPrimaryToken(state);
  const wallet = getWalletSelector(state);

  if (!accessToken || !wallet) return;

  try {
    yield call(cancelSubscription, accessToken, SITE_ID, wallet._id, payload);
    yield put(setEntitlementLoading(null));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'unable to cancel subscription');
    yield put(showAdminErrorModal('Unable to cancel the subscription'));
    yield put(setEntitlementLoading(null));
  }
};

export const editPaymentMethodSaga = function* ({ payload }: IEditPaymentMethodAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const isAdmin = isUserAdmin(state);
  const wallet: IWallet | null = getWalletSelector(state);

  if (!accessToken) {
    return;
  }

  try {
    if (!isAdmin) {
      // regular user
      if (!wallet) {
        return;
      }

      const editedInformation: IEditPaymentMethodParams = { ...payload, siteId: SITE_ID, walletId: wallet._id };
      yield call(
        submitEditPaymentMethod,
        accessToken,
        SITE_ID,
        editedInformation,
      );
      yield put(completeEditPaymentMethodProcess());
      yield call(updatePaymentsSaga);

    } else {
      // admin
      const editedPayment = { ...payload, siteId: SITE_ID };
      yield call(submitEditAdminPaymentMethod, accessToken, SITE_ID, editedPayment);
      yield call(updateAdminPaymentsSaga);
      yield put(completeEditPaymentMethodProcess());
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error updating payout method');
    yield put(showAdminErrorModal(`Unable to update payout method`));
  }
};

export const fetchViewerServiceChargeRateSaga = function* () {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    const result: number = yield call(getViewerServiceCharge, accessToken, SITE_ID);
    yield put(updateViewerServiceChargeRate(result));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e);
  }
};

export const getContractDataSaga = function* () {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);

  if (!accessToken) {
    return;
  }

  try {
    const contract: IContract = yield call(getContractData, accessToken, SITE_ID);
    const plan: Plan = yield call(getPlanData, accessToken, SITE_ID, contract.planId);

    if (!contract) throw new Error('Contract is null');
    if (!plan) throw new Error('Plan is null');
    if (contract.stripeCustomerId) {
      const { data }: { data: PaymentMethod[]} = yield call(
        getAdminPayments,
        accessToken,
        SITE_ID,
        contract.stripeCustomerId,
      );
      yield put(setPaymentMethods(data));
    }

    yield put(updateContractData(contract));
    yield put(updatePlanData(plan));
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error getting contract data');
  }
};

export const updateAdminPaymentsSaga = function*(){
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const contract: IContract | null = getContract(state);

  if (!accessToken || !contract || !contract.stripeAccountId) {
    return;
  }

  const { data }: { data: PaymentMethod[] } = yield call(
    getAdminPayments,
    accessToken,
    SITE_ID,
    contract.stripeCustomerId!,
  );

  yield put(setPaymentMethods(data));

};

export const deleteSingleUseSourceSaga = function* ({ payload }: IDeleteSourceAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const { cardId, customer } = payload;

  if (customer) {
    yield call(deleteSingleUseSource, { accessToken, cardId, customer });
  }
  yield put(removeSingleUseSourceCard(cardId));
  yield put(deleteSourceComplete(cardId));
};

export const purchasePlanSaga = function* ({ payload }: IPurchasePlanAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const contract = getContract(state);
  const isAdmin: boolean = isUserAdmin(state);

  if (!accessToken || !isAdmin || !contract) {
    return;
  }

  const { paymentMethod } = yield call(
    getStripePaymentMethodWithoutBillingInfo,
    payload.element,
    payload.stripe,
  );

  if (!paymentMethod) {
    return;
  }

  payload.paymentMethod = paymentMethod;
  // Deleting billing details otherwise stripe throws an error
  // @ts-ignore
  delete payload.paymentMethod!.billing_details;

  try {
    yield submitPaymentMethodSaga({ payload, type: 'billing/SUBMIT_PAYMENT_METHOD' });

    const { data }: { data: IContract } = yield call(
      purchasePlan,
      accessToken,
      contract.stripeCustomerId!,
      SITE_ID,
      payload.paymentMethod!,
    );

    yield put(purchasePlanComplete(data));
    yield put(dismissModal(ModalKinds.purchasePlan));
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.error(error, 'error purchasing plan');
    yield put(purchasePlanError('Payment transaction failed, please try again.'));
  }
};

export const upgradePlanSaga = function* ({ payload }: IUpgradePlanAction) {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const contract = getContract(state);
  const isAdmin: boolean = isUserAdmin(state);
  const newPlan: Plan = yield call(getPlanData, accessToken!, SITE_ID, payload.planId);

  if (!accessToken || !isAdmin || !contract) {
    throw new Error('Missing required data. Please try again.');
  }

  const { paymentMethod } = yield call(
    getStripePaymentMethodWithoutBillingInfo,
    payload.element,
    payload.stripe,
  );

  if (!paymentMethod) {
    throw new Error('Payment method is missing. Please try again.');
  }

  payload.paymentMethod = paymentMethod;
  // Deleting billing details otherwise stripe throws an error
  // @ts-ignore
  delete payload.paymentMethod!.billing_details;

  try {
    yield submitPaymentMethodSaga({ payload, type: 'billing/SUBMIT_PAYMENT_METHOD' });
    const changePlanParams: IChangePlanParams = {
      accessToken,
      paymentMethod: payload.paymentMethod!,
      planId: payload.planId,
      siteId: SITE_ID,
      stripeCustomerId: contract.stripeCustomerId!,
    };

    const { data }: { data: IContract } = yield call(
      changePlan,
      changePlanParams,
    );

    yield put(upgradePlanComplete(data));
    yield put(updatePlanData(newPlan));
    yield put(dismissModal(ModalKinds.upgradePlan));
    yield put(showModal( { data: { planName: newPlan.name }, kind: ModalKinds.upgradePlanSuccess }));
  } catch (error) {
    yield put(upgradePlanError('Payment transaction failed, please try again.'));
  }
};

export const nativeCurrencySaga = function* (action: any) {
  const { payload }: { payload: string | null } = action;
  const state: IState = yield select();
  const nativeCurrencyCode = getNativeCurrency(state);

  if (!payload) {
    if (nativeCurrencyCode) yield put(setNativeCurrency(null));
    return;
  }

  const countryCode = getCountryCode(state);
  if (payload === countryCode) return;

  try {
    const { currencyCode } = yield call(fetchNativeCurrency, payload);
    if (!currencyCode) return;

    yield put(setNativeCurrency(currencyCode));
  } catch (error) {
    console.error(error, 'error on fetchNativeCurrency'); // tslint:disable-line: no-console
  }
};

export const billingSaga = function* () {
  yield takeEvery(LOG_OUT, resetBillingSaga);
  yield takeLatest([LOG_IN_SUCCESS, GET_WALLET_DATA], getWalletDataSaga);
  yield takeLatest(LOG_IN_SUCCESS, fetchViewerServiceChargeRateSaga);
  yield takeLeading(SUBMIT_PAYMENT, submitPaymentSaga);
  yield takeLeading(PURCHASE_PLAN, purchasePlanSaga);
  yield takeLatest(FETCH_TAX_RATE, fetchTaxRateSaga);
  yield takeEvery(REMOVE_PAYMENT, removePaymentSaga);
  yield takeEvery(REMOVE_PAYMENT_CONFIRM, removePaymentConfirmSaga);
  yield takeEvery(SUBMIT_PAYMENT_METHOD, submitPaymentMethodSaga);
  yield takeEvery(SUBMIT_DEFAULT_PAYMENT_METHOD, submitDefaultPaymentMethodSaga);
  yield takeEvery(WATCH_ENTITLEMENT_ACCESS, watchEntitlementAccessSaga);
  yield takeLatest(CHECK_APPLE_PAY_VERIFY, checkApplePayVerify);
  yield takeEvery(COMPLETE_PAYMENT_PROCESS, updatePaymentsSaga);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(EDIT_PAYMENT_METHOD, editPaymentMethodSaga);
  yield takeEvery(GET_CONTRACT_DATA, getContractDataSaga);
  yield takeLatest(DELETE_SOURCE, deleteSingleUseSourceSaga);
  yield takeEvery(SET_COUNTRY_CODE_SAGA_ACTION, nativeCurrencySaga);
  yield takeEvery(UPGRADE_PLAN, upgradePlanSaga);
};
