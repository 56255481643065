import { ObjectValues } from 'utils';

export const TargetTypes = {
  CHANNEL_NAVIGATION: 'channelNavigation',
  COUNTDOWN: 'countdown',
  FOOTER: 'footer',
  FOOTER_LINKS: 'footerLinks',
  HOME_ID: 'homeId',
  LANDING: 'landing',
  LOGO: 'logo',
  NAVIGATION: 'navigation',
  OFFLINE_IMAGE: 'offlineImage',
  SIDEBAR: 'sidebar',
  SOCIAL: 'social',
  SPONSORS: 'sponsors',
  DEFAULT_THEME_ID: 'defaultThemeId',
} as const;
export type TargetTypes = ObjectValues<typeof TargetTypes>;

export const NO_IMAGE = 'noimage';

export enum ModeTypes {
  ACCESS_CONTROL = 'access-control',
  BROADCAST = 'broadcast',
  DESIGN = 'design',
  EDIT = 'edit',
  MAESTRO_STREAM = 'maestroStream',
  PANELS = 'panels',
  STREAM_METHOD_SELECT = 'streamMethodSelect',
  STUDIO_STREAM = 'studioStream',
}

export const settingsTypes = {
  ALLOW_NEW_ADMIN: 'ALLOW_NEW_ADMIN',
  BILLING: 'BILLING',
  CONCURRENTS: 'CONCURRENTS',
  CUSTOMER_PROFILE: 'CUSTOMER_PROFILE',
  DESIGN: 'DESIGN',
  ICONS: 'ICONS',
  LOCALIZATION: 'LOCALIZATION',
  LOGIN: 'LOGIN',
  LOGOS: 'LOGOS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  TAGS: 'TAGS',
  THIRD_PARTY: 'THIRD_PARTY',
  USER_PROFILES: 'USER_PROFILES',
  VIDEO_PLAYER: 'VIDEO_PLAYER',
} as const;

export type SettingsTypes = keyof typeof settingsTypes;

export enum ActionKey {
  pageOrChannel = 'pageOrChannel',
  streaming = 'stream',
  content = 'content',
  access = 'access',
  editor = 'editor',
  design = 'design',
  overlays = 'overlays',
  panels = 'panels',
  pageOrChannelSettings = 'pageOrChannelSettings',
  library = 'library',
  logos = 'logos',
  analytics = 'analytics',
  trends = 'trends',
  earnings = 'earnings',
  engagement = 'engagement',
  videos = 'videos',
  videoControls = 'videoControls',
  insights = 'insights',
  community = 'community',
  subs = 'ticketsAndSubs',
  settings = 'settings',
  thirdParty = '3rdPartyApps',
  achievements = 'achievements',
  admins = 'admins',
  billing = 'billing',
  developer = 'developer',
  icons = 'icons',
  localization = 'localization',
  login = 'loginIn',
  receipts = 'receipts',
  superAdmin = 'superAdmin',
  userProfile = 'userProfiles',
  help = 'help',
  wizard = 'wizard',
  siteAds = 'siteAds',
}
