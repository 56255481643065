import { call, put, select, takeEvery, fork, takeLatest } from 'redux-saga/effects';
import { getSiteId, getSiteSlug } from 'services/app/selectors';
import warning from 'warning';
import {
  getPrimaryToken, getUserRoles, getUserName, isUserAdmin,
} from 'services/auth/selectors';
import {
  IAPIKeyResponse,
  IDeleteAPIKeyResponse,
  IWebhooksResponse,
  IWebhooks,
} from './models';
import { showAdminErrorModal } from 'services/modals/actions';
import { getApiKeyData, getDeveloperSetting } from './selectors';
import {
  CREATE_API_KEY,
  createdApiKey,
  stopIsGeneratingApiKey,
  deletedApiKey,
  deleteApiKeyFailed,
  DELETE_API_KEY,
  FIND_API_KEY,
  findApiKey,
  foundApiKey,
  failedToFindApiKey,
  FIND_WEBHOOKS,
  INSERT_WEBHOOKS,
  UPDATE_WEBHOOKS,
  findWebhooks,
  foundWebhooks,
  failedToFindWebhooks,
  insertWebhooks,
  updateWebhooks,
  UPDATE_DEVELOPER_SETTING,
  IInsertWebhooksAction,
  IUpdateWebhooksAction,
  IUpdateDeveloperSettingAction,
  webhookUrlChanged,
  resetWebhooks,
  updateSaved,
  setCodeInjection,
} from './actions';
import {
  createApiKey,
  deleteApiKey,
  getApiKey,
  postWebhooks,
  putWebhooks,
  getCodeInjectionCode,
  updatePlainTextCodeInjection,
} from './api';
import { isEqual } from 'lodash';
import { LOG_IN_SUCCESS } from 'services/auth';
import IState from 'services/state';
import { subscribe, IUpdateDocumentAction, UPDATE_DOCUMENT } from 'services/realtime/actions';
import { joinKeySegments } from 'firebase-utils';
import { getSiteSettingsId } from '../customer-profile/selectors';
import { getCodeInjections } from 'services/app/selectors';

export const createApiKeySaga = function* () {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  const clientName = getSiteSlug(state);
  const roles = getUserRoles(state);
  const username = getUserName(state);

  try {
    const result: IAPIKeyResponse = yield call(createApiKey, clientName, username, siteId, roles, primaryToken);
    if (result.status === 200) {
      yield put(createdApiKey(result.data));
    } else {
      yield put(stopIsGeneratingApiKey());
      yield put(showAdminErrorModal(result.errorMessage || 'Internal Error occurred while creating Api key.'));
    }
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data || error;
    yield put(stopIsGeneratingApiKey());
    yield put(showAdminErrorModal(message));
  }
};

export const deleteApiKeySaga = function* () {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  const { data } = getApiKeyData(state);
  if (!data) {
    yield put(showAdminErrorModal('No Api Key to delete.'));
    return;
  }
  const { _id } = data;

  try {
    const result: IDeleteAPIKeyResponse = yield call(deleteApiKey, _id, primaryToken, siteId);
    if (result.status === 200) {
      yield put(deletedApiKey());
      yield put(resetWebhooks());
    } else {
      yield put(deleteApiKeyFailed());
      yield put(showAdminErrorModal(result.errorMessage || 'Internal Error occurred while revoking Api key.'));
    }
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data || error;
    yield put(showAdminErrorModal(message));
  }
};

export const findApiKeySaga = function* () {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  try {
    const result: IAPIKeyResponse = yield call(getApiKey, primaryToken, siteId);
    if (result.status === 200 && result.data) {
      yield put(foundApiKey(result.data));
    } else {
      yield put(failedToFindApiKey());
    }
  } catch (e) {
    yield put(failedToFindApiKey());
  }
};

export const findWebhooksSaga = function* () {
  const state = yield select();
  const siteSettingsId = getSiteSettingsId(state);
  if (!siteSettingsId) {
    warning(true, `missing settingsId`);
    return;
  }
  const path = joinKeySegments(['site_setting', siteSettingsId]);
  yield put(subscribe(path));
};

export const insertWebhooksSaga = function* ({ payload: webhooks }: IInsertWebhooksAction) {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);

  try {
    const result: IWebhooksResponse = yield call(postWebhooks, primaryToken, siteId, webhooks);
    if (result.status === 200) {
      yield put(updateSaved(true));
    } else {
      yield put(showAdminErrorModal(result.errorMessage || 'Internal Error occurred while inserting Webhooks'));
    }
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data || error;
    yield put(showAdminErrorModal(message));
  }
};

export const updateWebhooksSaga = function* ({ payload: webhooks }: IUpdateWebhooksAction) {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  try {
    const { errorMessage, status }: IWebhooksResponse = yield call(putWebhooks, primaryToken, siteId, webhooks);
    if (status === 200) {
      yield put(updateSaved(true));
    } else {
      yield put(showAdminErrorModal(errorMessage || 'Internal Error occurred while updating Webhooks'));
    }
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data || error;
    yield put(showAdminErrorModal(message));
  }
};

export const updateDeveloperSettingSaga = function* ({ payload: newDeveloperSetting }: IUpdateDeveloperSettingAction) {
  const state = yield select();
  const primaryToken = getPrimaryToken(state) || '';
  const siteId = getSiteId(state) || '';

  const { webhooks: { data: currentWebhooks }, codeInjection: currentCodeInjection } = getDeveloperSetting(state);
  const { webhooks: newWebhooks, codeInjection: newCodeInjection = {} } = newDeveloperSetting;
  const oldHeader = currentCodeInjection?.header;
  const oldBody = currentCodeInjection?.body;
  const { header: newHeader, body: newBody } = newCodeInjection;
  if (!isEqual(currentWebhooks, newWebhooks) && newWebhooks && newWebhooks.webhookUrl) {
    if (!(currentWebhooks && currentWebhooks.webhookUrl)) {
      yield put(insertWebhooks(newWebhooks));
    }
    yield put(updateWebhooks(newWebhooks));

    const hasWebhookUrlChanged = currentWebhooks.webhookUrl !== newWebhooks.webhookUrl;
    if (hasWebhookUrlChanged) {
      yield put(updateSaved(true));
      yield put(webhookUrlChanged(true));
    }
  }

  if (!isEqual(currentCodeInjection, newCodeInjection)) {
    if (!isEqual(oldHeader, newHeader)) {
      yield put(setCodeInjection({ type: 'header', value: newHeader }));
    }

    if (!isEqual(oldBody, newBody)) {
      yield put(setCodeInjection({ type: 'body', value: newBody }));
    }
    try {
      yield call(updatePlainTextCodeInjection, {
        body: newBody || '',
        header: newHeader || '',
        primaryToken,
        siteId,
      });
      yield put(updateSaved(true));
    } catch(e) {
      const error = e?.response?.statusText || e.message || 'Unknown error';
      const message = e?.response?.data || error;
      yield put(showAdminErrorModal(message));
    }
  }
};

export const watchRealtimeDocUpdateSaga = function* ({ payload }: IUpdateDocumentAction) {
  const { path, value } = payload;
  const updatedSiteSetting = path.match(/^site_setting\/(.+)/);

  if (!updatedSiteSetting || !value) {
    return;
  }
  const { developerApiKey } = value;
  const { webhooks } = value;
  if(!developerApiKey) {
    yield put(failedToFindWebhooks());
    return;
  }
  if(webhooks) yield put(foundWebhooks(webhooks as IWebhooks));
};


export const getCodeInjection = function* () {
  const state: IState = yield select();
  const codeInjections = getCodeInjections(state);
  const { header, body } = codeInjections || {};
  try {
    if (header) {
      const { data, status } = yield call(getCodeInjectionCode, `${header}?timestamp=${Date.now().toString()}`);
      if (status === 200) {
        yield put(setCodeInjection({ type: 'header', value: data }));
      }
    }

    if (body) {
      const { data, status } = yield call(getCodeInjectionCode, `${body}?timestamp=${Date.now().toString()}`);
      if (status === 200) {
        yield put(setCodeInjection({ type: 'body', value: data }));
      }
    }
  } catch (e) {
    const error = e?.response?.statusText || e.message || 'Unknown error';
    const message = e?.response?.data || error;
    yield put(showAdminErrorModal(message));
  }
};

export const getDeveloperSettingSaga = function* () {
  const state: IState = yield select();
  const isAdmin = isUserAdmin(state);
  if (isAdmin) {
    yield put(findApiKey());
    yield put(findWebhooks());
  }
};

export default function* developerSaga() {
  yield fork(getDeveloperSettingSaga);
  yield fork(getCodeInjection);
  yield takeEvery(LOG_IN_SUCCESS, getDeveloperSettingSaga);
  yield takeEvery(CREATE_API_KEY, createApiKeySaga);
  yield takeEvery(DELETE_API_KEY, deleteApiKeySaga);
  yield takeEvery(FIND_API_KEY, findApiKeySaga);
  yield takeEvery(FIND_WEBHOOKS, findWebhooksSaga);
  yield takeEvery(INSERT_WEBHOOKS, insertWebhooksSaga);
  yield takeEvery(UPDATE_WEBHOOKS, updateWebhooksSaga);
  yield takeLatest(UPDATE_DEVELOPER_SETTING, updateDeveloperSettingSaga);
  yield takeEvery<IUpdateDocumentAction>(UPDATE_DOCUMENT, watchRealtimeDocUpdateSaga);
}
