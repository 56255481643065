import IModel from './IModel';

export enum SubscriptionRecurrence {
  once = 'once',
  month = 'month',
  year = 'year',
}

export interface IPrice {
  currency: string;
  default: boolean;
  stripePriceId?: string;
  value: number;
}

export enum SubscriptionType {
  ticket = 'ticket',
  subscription = 'subscription',
}

export default interface ISubscription extends IModel {
  buttonColor?: string;
  description?: string;
  eventDate?: number;
  name: string;
  payouts?: string[],
  previewDurationSeconds?: number;
  price?: number; // needs-update - remove this once we're confident to rely on prices
  prices: IPrice[];
  recurrence?: SubscriptionRecurrence;
  siteId: string;
  sku: string;
  subscriptionProvider: string;
  type?: SubscriptionType,
  visible?: boolean;
}
