import { createSelector } from 'reselect';
import IState from 'services/state';
import omitBy from 'lodash/omitBy';
import { defaultProfileImages, defaultCustomTextModal, pageTypes } from 'services/app/constants';
import { NO_IMAGE } from 'services/admin/constants';
import {
  BASE_PATH,
  FORCE_ENABLE_MAESTRO_LOGIN,
  LEGACY_DOMAIN,
  SITE_SLUG,
  EMBED,
  COMPOSITE_OVERLAYS,
} from 'config';
import { THEME_TYPES } from 'models/ITheme';

export const EMPTY_ARRAY: any[] = [];
export const EMPTY_OBJECT = {};

// CreatedSelectors for combinedSelectors EPIC-SRE-216
export const getApp = createSelector(
  (state: IState) => state.app,
  (app) => app,
);
// End of CreatedSelectors for combinedselectors

export const getSiteContract = createSelector(
  (state: IState) => state.app.site.contract,
  (contract) => contract,
);
export const getTimeOffset = (state: IState) => state.app.timeOffset;
export const getPageCollection = createSelector(
  (state: IState) => state.app.object?.collection,
  (coll) => coll,
);
export const getPageId = (state: IState) => state.app.object?._id;
export const getPageName = (state: IState) => state.app.object?.data?.name || state.app.object?.seo?.title;
export const getPageSlug = (state: IState) => state.app.object?.slug;
export const getPageType = (state: IState) => state.app.object?.type;

export const getSiteDomain = (state: IState) => state.app.site?.settings?.domain?.url;
export const getSiteId = (state: IState) => state.app.site?._id;
export const getSitePackageId = (state: IState) => `${state.app.site?.package?.id}-sapphire`;
export const getSitePackageVersion = (state: IState) => `${state.app.site?.package?.version}-sapphire`;
export const getSiteSlug = (state: IState) => state.app.site?.slug;
export const getSiteProfileImages = createSelector(
  (state: IState) => state.app.site?.settings?.userProfiles?.profileImages,
  (profileImages) => profileImages || defaultProfileImages,
);

export const getLocalization = createSelector(
  (state: IState) => state.app.site?.settings?.localization,
  (loc) => loc || EMPTY_OBJECT,
);

// insights site stuff
// TODO: figure out what we're doing for id and versions
export const getSite = createSelector(
  (state: IState) => state.app.site,
  (site) => site,
);
export const getSiteProfileSettings = createSelector(
  [(state: IState) => state.app.site?.settings?.userProfiles?.changeName, getSiteProfileImages],
  (name, images) => ({
    changeName: name || false,
    profileImages: images || defaultProfileImages,
  }),
);

export const getCodeInjections = createSelector(
  (state: IState) => state.app.site?.settings?.codeInjection,
  (codeInjection) => codeInjection,
);
export const getCustomTextModal = createSelector(
  (state: IState) => state.app.site?.settings?.login,
  (login) => login || defaultCustomTextModal,
);
export const getCustomerProfile = createSelector(
  (state: IState) => state.app.site?.settings?.customerProfile,
  (customerProfile) => customerProfile,
);

export const getPage = createSelector(
  (state: IState) => state.app.object,
  (obj) => obj,
);
export const getPages = createSelector(
  (state: IState) => state.app.site?.regions?.['channel-select'].pages,
  (pages) => pages,
);
export const getServices = createSelector(
  (state: IState) => state.app.site?.settings?.services,
  (services) => services,
);
export const getTaxes = createSelector(
  (state: IState) => state.app.site?.tax,
  (tax) => tax,
);
export const getMarketingOptin = createSelector(
  (state: IState) => state.app.site?.settings?.login?.marketingOptin,
  (optin) => optin,
);
export const getMarketingOptinLabel = createSelector(
  (state: IState) => state.app.site?.settings?.login?.marketingOptinLabel,
  (label) => label,
);
export const getFacebookAppId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.facebookAppId,
  (facebookId) => facebookId,
);
export const getFacebookPixelId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.facebookPixelId,
  (facebookPixelId) => facebookPixelId,
);
export const getFaceit = createSelector(
  (state: IState) => state.app.site?.settings?.services?.faceit,
  (faceIt) => faceIt,
);
export const getGoogleAnalyticsId = createSelector(
  (state: IState) => state.app.site?.settings?.services?.googleAnalyticsId,
  (googleAnalyticsId) => googleAnalyticsId,
);
export const getScheduledEvents = createSelector(
  (state: IState) => state.app.object?.data?.content?.live?.schedule,
  (schedule) => schedule,
);

export const getObject = createSelector(
  (state: IState) => state.app.object,
  (obj) => obj,
);

export const isGoogleAnalyticsDisabled = (state: IState) =>
  state.app.site?.settings?.services?.googleAnalyticsDisabled || false;

export const getActiveSiteFeatures = createSelector(
  (state: IState) => state.app.site?.features,
  (features) => {
    const fullMap = features || EMPTY_OBJECT;
    return omitBy(fullMap, (val) => typeof val !== 'boolean');
  },
);

export const getActiveSitePanelsFeatures = createSelector(
  (state: IState) => state.app.site?.features,
  siteFeatures => siteFeatures.panels,
);

export const getPageViewType = (state: IState) => {
  return state.app.object?.type || pageTypes.CHANNEL;
};

export const isLandingPageType = (state: IState) => getPageViewType(state) === 'landing';

export const getActiveSitePanels = createSelector(
  (state: IState) => state.app.site?.features?.panels,
  (panels) => panels || EMPTY_OBJECT,
);

export const getActiveSiteOverlays = createSelector(
  (state: IState) => state.app.site?.features?.overlays,
  (overlays) => overlays || EMPTY_OBJECT,
);

export const getActiveSiteQuestActions = createSelector(
  (state: IState) => state.app.site?.features?.questActions,
  (questActions) => questActions || EMPTY_OBJECT,
);

// function syntax to resolve circular dependency with auth selectors
export const getUserMap = createSelector(
  (state: IState) => state.app.site?.users,
  (users) => users || EMPTY_OBJECT,
);

export const getSavedStyle = createSelector(
  (state: IState) => state.themes.theme || EMPTY_OBJECT,
  (style) => style,
);

export const getSavedPageStyle = createSelector(
  (state: IState) => state.app.object?.style,
  (style) => style,
);

export const getThemeFromStyle = (style: Record<string, any>, classicThemeOptions: Record<string, any>) => {
  const isClassicTheme = style.type === THEME_TYPES.CLASSIC;
  return {
    colors: {
      ...style.colors,
      ...(EMBED && COMPOSITE_OVERLAYS && { surface1: 'transparent' }),
      ...(isClassicTheme &&
        classicThemeOptions && {
          accentPrimary: classicThemeOptions.accentPrimary,
          accentSecondary: classicThemeOptions.accentSecondary,
        }),
    },
    typography: {
      ...style.typography,
    },
  };
};

const getSiteCollections = createSelector(
  (state: IState) => state.app.site.collections,
  (collections) => collections,
);

export const channelSelectEnabled = (state: IState) =>
  state.app.site.regions?.['channel-select']?.state === 'on' || false;

export const getActiveChannels = createSelector(
  (state: IState) => state.app.site.regions?.['channel-select']?.pages,
  (pages) => pages || EMPTY_ARRAY,
);

export const getWelcomeScreen = createSelector(
  (state: IState) => state.app.site.settings?.welcomeScreen,
  (screen) => screen || EMPTY_OBJECT,
);

export const getCurrentChannelId = (state: IState) => state.app?.object?._id;

export const channelSelectEnabledPendingChanges = (state: IState) => {
  const pendingState = state.admin.pendingAdminChanges?.channelNavigation?.pendingData?.state;
  const existingEnabledState = channelSelectEnabled(state);
  return pendingState ? pendingState === 'on' : existingEnabledState;
};

export const getSiteCollectionsMap = createSelector([getSiteCollections], (collections) => {
  const map: Record<string, any> = {};
  for (const [collection, { slug }] of Object.entries<{ slug: string }>(collections)) {
    map[slug] = collection;
  }
  return map;
});

export const isTimeOffsetLoaded = (state: IState) => state.app.timeOffsetLoaded;

// function syntax to resolve circular dependency with auth selectors
// DO YOU FEAR THE WRATH OF ANDY? THEN DO NOT MEMOIZE getServerTime
export function getServerTime(state: IState) {
  return state.app.timeOffsetLoaded ? Date.now() + state.app.timeOffset : null;
}
export const getChannelSelectPages = createSelector(
  (state: IState) => state.app?.site?.regions?.['channel-select']?.pages,
  (pages) => pages,
);

export const getChannelVideoContent = createSelector(
  (state: IState) => state.app.object?.data?.content,
  (content) => content,
);

export const getChannelScheduleContent = createSelector(
  (state: IState) => state.app.object?.data?.content?.live?.schedule,
  (schedule) => schedule,
);

export const getObjectSlug = (state: IState) => state.app?.object?.slug;

export const getObjectId = (state: IState) => state.app.object?._id;

export const getCountdownData = createSelector(
  (state: IState) => state.app.object?.data?.countdown,
  (countdown) => countdown || EMPTY_OBJECT,
);

const getChannelNavigationDoc = createSelector(
  (state: IState) => state.app.object?.renderers?.navigation,
  (navigation) => navigation,
);

export const getSiteNavigationDoc = createSelector(
  (state: IState) => state.app.site?.regions?.navigation,
  (navigation) => navigation || EMPTY_OBJECT,
);

export const getNavigationDoc = createSelector(
  [getChannelNavigationDoc, getSiteNavigationDoc],
  (channelNav, siteNav) => {
    return channelNav || siteNav;
  },
);

export const getNavigationMap = createSelector([getNavigationDoc], (navDoc) => ({
  channelSpecific: navDoc?.channelSpecific || false,
  items: navDoc?.items || EMPTY_ARRAY,
  state: navDoc?.state || 'off',
}));

export const getHeaderData = createSelector(
  (state: IState) => state?.app?.site?.regions?.header,
  (header) => header,
);

export const getAuthenticationMap = createSelector(
  (state: IState) => state?.app?.site?.regions?.header?.authentication,
  (auth) => auth || EMPTY_OBJECT,
);

export const getFooterData = createSelector(
  (state: IState) => state?.app?.site?.regions?.footer,
  (footer) => footer,
);

export const getSiteSocialLinks = createSelector(
  (state: IState) => state?.app?.site?.regions?.footer?.socials,
  (socials) => socials || EMPTY_ARRAY,
);

export const getSiteTweetMessage = createSelector(
  (state: IState) => state?.app?.site?.regions?.footer?.tweetMessage,
  (message) => message,
);

export const getSiteHashtag = createSelector(
  (state: IState) => state.app.site.regions?.footer?.hashtag,
  (hash) => hash,
);

export const getChannelSocials = createSelector(
  (state: IState) => state.app?.object?.renderers?.socials,
  (socials) => socials,
);

export const getChannelSocialLinks = createSelector(
  (state: IState) => state.app?.object?.renderers?.socials?.links,
  (links) => links,
);

export const getChannelHashtag = createSelector(
  (state: IState) => state.app?.object?.renderers?.socials?.hashtag,
  (tag) => tag,
);

const getChannelTweetMessage = createSelector(
  (state: IState) => state.app?.object?.renderers?.socials?.tweetMessage,
  (message) => message,
);

export const getSocialLinks = createSelector(
  [getChannelSocials, getChannelSocialLinks, getSiteSocialLinks],
  (channelSocials, channelLinks, siteLinks) => {
    if (channelSocials) {
      return channelLinks || EMPTY_ARRAY;
    }
    return siteLinks;
  },
);

export const getTweetMessage = createSelector(
  [getChannelTweetMessage, getSiteTweetMessage],
  (channelMessage, siteMessage) => channelMessage || siteMessage || '',
);

const getRawAuths = createSelector(
  (state: IState) => state.app.site?.regions?.header?.authentication,
  (auth) => auth,
);

const ENABLE_SQUARE_ENIX_AUTH = ['andy-presentation', 'square-enix'].includes(SITE_SLUG);

export const getAuths = createSelector([getRawAuths], (auths) => {
  return {
    ...auths,
    squareenix: ENABLE_SQUARE_ENIX_AUTH,
  };
});

export const isSiteLoginEnabled = (state: IState) =>
  Object.values(getAuths(state)).some((enabled) => enabled) || FORCE_ENABLE_MAESTRO_LOGIN;

export const getPlayerData = createSelector(
  (state: IState) => state.app.site.regions?.player,
  (player) => player,
);

export const getPlayerSocials = createSelector(
  (state: IState) => state.app.site.regions?.player?.socials,
  (socials) => socials || EMPTY_OBJECT,
);

export const getShowVideoTitleFlag = createSelector(
  (state: IState) => state.app.site.regions?.player?.showVideoTitle,
  (showVideoTitle) => showVideoTitle,
);

export const getShowVideoUrlFlag = (state: IState) => {
  const flag = getPlayerData(state)?.showVideoUrl;
  return typeof flag === 'boolean' ? flag : true;
};

export const getShowVideoEmbedFlag = (state: IState) => {
  const flag = getPlayerData(state)?.showVideoEmbed;
  return typeof flag === 'boolean' ? flag : true;
};

export const getRedirectUrl = (state: IState) => {
  const {
    location: { pathname },
  } = state.router;
  return `${window.location.protocol}//${window.location.host}${BASE_PATH}${pathname}`;
};

export const isMaestroLoginEnabled = (state: IState) => Boolean(FORCE_ENABLE_MAESTRO_LOGIN || getAuths(state)?.maestro);

export const onChannel = (state: IState) =>
  state.app.object?.type === 'channel' || state.app.object?.type === 'landing';

export const fontUrl = `https://${LEGACY_DOMAIN}/pkg/font/1/public`;

const getDefaultLogo = createSelector(
  (state: IState) => state.app.site.regions?.header.defaultLogo,
  (logo) => logo,
);

const getLegacySiteLogo = createSelector([getHeaderData], (header) => {
  return {
    logoLink: header?.logoLink,
    logoMobile: header?.mobileLogo,
    logoWeb: header?.logo,
  };
});

// deprecated
export const getSiteLogo = createSelector(
  [getDefaultLogo, getLegacySiteLogo],
  (defaultLogo, legacySiteLogo) => defaultLogo || legacySiteLogo,
);

export const getPageLogo = createSelector([getHeaderData], (header) => {
  return {
    logoLink: header?.logoLink,
    logoMobile: header?.logo,
    logoTitle: header?.logoTitle,
    logoWeb: header?.logo,
  };
});

export const hasPageLogoImage = (state: IState) =>
  getPageLogo(state)?.logoWeb && getPageLogo(state)?.logoWeb !== NO_IMAGE;

export const getLogo = (state: IState) => getPageLogo(state);

export const getLogoLink = (state: IState) => getPageLogo(state)?.logoLink;

export const getLogoTitle = (state: IState) => getLogo(state)?.logoTitle;

// deprecated
export const getSiteLogoMobile = (state: IState) => getSiteLogo(state)?.logoMobile;

// deprecated
export const getSiteLogoWeb = (state: IState) => getSiteLogo(state)?.logoWeb;

// deprecated
export const getSiteLogoTitle = (state: IState) => getSiteLogo(state)?.logoTitle;

// deprecated
export const getSiteLogoLink = (state: IState) => getSiteLogo(state)?.logoLink;

const getBaseLanguage = createSelector(
  (state: IState) => state.app.site?.settings?.localization?.baseLanguage,
  (baseLanguage) => baseLanguage || 'en',
);

// old location on site settings
const getLegacyBaseLanguage = createSelector(
  (state: IState) => state.app.site?.settings?.baseLanguage,
  (lang) => lang,
);

export const getSiteBaseLanguage = createSelector(
  [getLegacyBaseLanguage, getBaseLanguage],
  (legacyBase, base) => legacyBase || base,
);

export const getCustomVideoOverlayUrl = (state: IState) => {
  const { object } = state.app;
  const { enabled = false, url = null } = object?.data?.customVideoOverlay! || EMPTY_OBJECT as any;
  return (enabled && typeof url === 'string' && url.trim()) || null;
};
export const showConcurrents = createSelector(
  (state: IState) => state.app.site.settings?.showConcurrents,
  (showconcurrents) => showconcurrents || showconcurrents === undefined,
);

export const getMinimumConcurrents = (state: IState) => {
  const { minimumConcurrents } = state.app.site?.settings || {};
  return minimumConcurrents || 0;
};

export const getSavedPageArtwork = createSelector(
  (state: IState) => state.app.object?.data?.artwork,
  (artWork) => artWork,
);

export const getSavedSubscriptionGateBackgroundMobileImage = createSelector(
  (state: IState) => state.app.object?.data?.gate?.gate?.subscriptionGateBackgroundMobile,
  (bckg) => bckg,
);
export const getSavedSubscriptionGateBackgroundWebImage = createSelector(
  (state: IState) => state.app.object?.data?.gate?.gate?.subscriptionGateBackgroundWeb,
  (web) => web,
);

export const getParentDomains = createSelector(
  (state: IState) => state.app.parentDomains,
  (domains) => domains,
);
export const getActivePlayerOverride = createSelector(
  (state: IState) => state.app.site?.settings?.playerOverride,
  (playerOverride) => playerOverride || null,
);

