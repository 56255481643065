import IState from 'services/state';
import { parseDataFromAccessToken } from 'services/auth/api';
import { RoleScope } from 'services/auth/models';
import { createSelector } from 'reselect';

// TODO: Mark as deprecated, prefer getUserAccessToken?
export function getUserToken(state: IState): string | null {
  return state.auth.accessToken;
}

// TODO: Mark as deprecated, perfer getAccessTokenData?
export const getUserTokenData = createSelector(
  [(state: IState) => state.auth?.accessToken || null],
  (token: string | null) => {
    return token ? parseDataFromAccessToken(token) : null;
  },
);

export const isUserSiteAdminRole = createSelector(
  [getUserTokenData],
  (userTokenData): boolean => {
    if (!userTokenData) {
      return false;
    }
    return userTokenData.roles
      .some((role) => role.scope === RoleScope.SiteAdmin && role.write);
  },
);

export const isUserProducerRole = createSelector(
  [getUserTokenData, (state: IState) => state.app?.object?._id],
  (userTokenData, pageId): boolean => {
    if (!userTokenData) {
      return false;
    }
    return userTokenData.roles
      .some((role) => (
        role.scope === RoleScope.Producer && (!role.pageId?.length || role.pageId.includes(pageId))
      ) && role.write);
  },
);

// generally, we consider both producer and site admin as admins
export const isUserAdmin = createSelector(
  [isUserProducerRole, isUserSiteAdminRole],
  (isProducer, isSiteAdmin): boolean => isProducer || isSiteAdmin,
);

export const getIsUserMaestroAdmin = createSelector([getUserTokenData, isUserAdmin], (userTokenData, isAdmin): boolean => {
  if (!userTokenData?.email) {
    return false;
  }
  return isAdmin && userTokenData?.email.includes('@maestro.io');
});

export const isAdminBarExpanded = (state: IState) => (
  state.auth.account?.isAdminBarExpanded
);

export const getAdminBarSubMenuQueue = createSelector(
  (state: IState) => state.auth.account?.adminBarSubMenuQueue,
  (subMenuQueue) => subMenuQueue,
);

export const getAdminSites = (state: IState) => state.auth.globalAccount.adminSites;
export const getGlobalAccountAccessToken = (state: IState) => state.auth.globalAccount.accessToken;
export const getGlobalAccountAdminSites = (state: IState) => state.auth.globalAccount.adminSites;
export const getGlobalAccount = (state: IState) => state.auth.globalAccount.account;
